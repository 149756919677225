@import './Variables/Variables.scss';

.about {
  width: 100%;
}

.first-section__about, .second-section__about {
  display: flex;
}

.first-section-text__about h2, .second-section-text__about h2 {
  @include h-text;
  margin-bottom: 46px;
}

.first-section-text__about p, .second-section-text__about p {
  @include p-text;
}

.first-section-text__about, .second-section-text__about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 70px;
  width: 50%;
}

.second-section-text__about h2 {
  display: flex;
  align-items: center;
}

.second-section-text__about h2 img {
  width: 226px;
  height: 46.31px;
}

.aboutTruhu, .aboutSeboya {
  width: 50%;
}

.aboutTruhu img, .aboutSeboya img {
  width: 100%;
}

@media only screen and (min-width: 1920px) {
  .about {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
  }
}

@media only screen and (max-width: 1280px) {
  .first-section__about {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .second-section__about {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .first-section-text__about, .second-section-text__about {
    width: 634px;
    padding: 0;
  }

  .first-section-text__about {
    margin-top: 70px;
  }

  .aboutSeboya {
    margin-bottom: 70px;
  }
  
  .aboutSeboya, .aboutTruhu, .second-section-text__about {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1048px) {
  .first-section-text__about, .second-section-text__about {
    width: 70%;
    padding: 50px;
  }
}

@media only screen and (max-width: 878px) {
  .aboutTruhu, .aboutSeboya {
    width: 522px;
    height: 637px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .first-section-text__about {
    margin-top: 130px;
  }

  .first-section-text__about, .second-section-text__about {
    padding: 0;
  }

  .first-section-text__about h2, .second-section-text__about h2 {
    font-size: 28px;
    line-height: 28.8px;
  }

  .first-section-text__about p, .second-section-text__about p {
    font-size: 18px;
  }
  
  .logo__about {
    width: 126px!important;
    height: auto;
  }

  .aboutTruhu, .aboutSeboya {
    width: 70%;
    height: auto;
  }

  .aboutTruhu img, .aboutSeboya img {
    width: 100%;
    height: auto;
  }

  .aboutSeboya {
    margin-bottom: 30px;
  }
}