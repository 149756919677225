.products__list {
  display: flex;
  gap: 15px;
}

.product {
  border: 1px solid black;
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.product button {
  width: 200px;
}

.payment__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  z-index: 100;
  margin-bottom: 10%;
}

.payment__list h3 {
  font-weight: 700;
  font-size: 42px;
  line-height: 72px;
}

.form__card {
  margin-top: 30px;
}

.payment__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
}

.payment__button {
  width: 265px !important;
}

.payment__button-cancel {
  cursor: pointer;
  font-weight: 700 !important;
}

.product__style {
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 5px 15px;
  width: 502px;
  background-color: white;
}

.payment__list form {
  width: 502px;
}

.product__input {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.product__style:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.product__style:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.product__style div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.product__style input {
  width: 16px !important;
  display: flex;
}

.product__description {
  width: 410px !important;
  padding-bottom: 10px;

  &.offer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    row-gap: 5px;

    p.introductory__offer {
      font-size: 25px;
      font-weight: 600;
      color: #222222;
    }

    .regular__offer {
      font-size: 18px;
      font-weight: 400;
      color: #656565;
    }
  }
}

//LAYOUT

.payment__page {
  width: 100%;
  position: relative;
  background: rgba(234, 234, 234, 0.5);
}

.payment__header {
  display: block;
}

.subscription-title {
  margin: 15px 0 15px 30px;
}

.payment__header a img {
  margin-left: 0px;
  display: inline;
}

.payment__flex {
  display: flex;
  flex-direction: row;
}

.payment__form {
  width: 50%;
  // height: 1055px;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 3%;
}

.card-error {
  margin-top: 15px;
}

.payment__form>form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.span__error-stripe {
  color: red !important;
  text-decoration: none !important;
  display: block;
  width: 501px;
}

.payment__form h2 {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

.payment__form p {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 24px;
}

.payment__form span {
  color: #59bdef;
  text-decoration: underline;
}

.payment__form button {
  width: 501px;
  height: 66px;
  background-color: #247e69;
  color: white;
  font-size: 20px;
  font-weight: 400px;
  line-height: 24px;
  border-radius: 8px;
}

.payment__form .subscribe__button {
  width: 260px;
}

.payment__form input {
  width: 501px;
  height: 46px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  padding: 5px 15px;
}

.payment__form label {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.payment__img {
  width: 50%;
  height: 100% !important;
  // height: 1149px !important;
  background: url('../assets/webp_images/sign_in_img.webp');
  position: absolute;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  right: 0;
}

.payment__circles {
  position: absolute;
  top: 200px;
}

.text-green {
  color: #247e69 !important;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.text-bold {
  color: black !important;
  text-decoration: none !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 1421px) {
  .payment__circles {
    display: none;
  }
}

@media only screen and (max-width: 1133px) {
  .payment__img {
    display: none;
  }

  .payment__flex {
    justify-content: center;
  }
}

@media only screen and (max-width: 730px) {
  .payment__form>form {
    margin-left: 0 !important;
    align-items: center;
  }

  .payment__form {
    margin-top: 3%;
    width: 550px;
  }

  .payment__form h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 628px) {
  .payment__list {
    margin-bottom: 4%;
  }

  .payment__form button,
  .payment__form input,
  .payment__list form {
    width: 301px;
  }

  .payment__form .subscribe__button {
    width: 260px;
  }

  .payment__form {
    margin-top: 3%;
    height: 1150px;
  }

  .product__style,
  .card__input-stripe {
    width: 350px;
    margin: 0 auto;
  }

  .product__description {
    width: 250px !important;
  }

  .introductory__offer {
    font-size: 19px;
  }

  .regular__offer {
    font-size: 16px;
  }

  .span__error-stripe {
    display: inline;
  }

  .payment__button {
    width: 186px !important;
  }
}

@media only screen and (max-width: 389px) {

  .product__style,
  .card__input-stripe {
    width: 286px;
    margin: 0 auto;
  }

  .subscribe__button {
    align-self: center;
  }
}