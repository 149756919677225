.colored-link {
  color: #247E69;
}

.user__page {
  height: 900px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px;
  width: 80%;
}

.user__page-links {
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.user__page-links p{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #959595;
}

.user__page-child {
  display: flex;
  gap: 30px;
  justify-content: center;
  width: 350px;
}

.user__page h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 26.4px;
}

.user__page p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.user_info {
  display: flex;
  gap: 20px;
  align-items: center;
}

.user_info div{
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.user_info button{
  font-weight: 700;
  font-size: 36px;
  line-height: 43.2px;
}

.personal__information, .personal__information form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.personal__information form input {
  width: 499px;
  height: 46px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  padding: 0 10px;
}

.personal__information form label {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.personal__information form p span {
  color: #59bdef;
}

.cancel-subscription {
  text-decoration: underline;
  color: red;
  margin-bottom: 46px;
}

// SUBSCRIPTION PAGE

.subscription__page, .subscription__info, .subscription__card-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.subscription__card {
  position: relative;
}

.card-logo {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 82px;
}

.date-info {
  font-weight: 600;
}

.subscription__card {
  width: 265px;
  height: 168px;
  background: linear-gradient(296.89deg, #247E69 24.28%, #2B9280 71.38%);
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
}

.subscription-cancelled {
  width: 590px;
  margin-bottom: 46px;
}

.subscription__button {
  width: 265px;
  height: 64px;
  border-radius: 8px;
  background-color: #0b0b0b;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.circle {
  background-color: #247E69;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  color: white;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.icon-userinfo {
  display: inline;
  width: 19px;
  margin-right: 10px;
}

.subscribe-page button {
  width: 265px;
}

.userinfoCircles {
  position: absolute;
  right: 0;
  top: 0;
}

.personal__information-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personal__information-buttons button {
  width: 216px;
  height: 64px;
  border-radius: 8px;
  background-color: #0b0b0b;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.word-break-info {
  word-break: break-all;
}

@media only screen and (max-width: 1120px) {
  .user__page {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    justify-content: center;
    gap: 50px;
  }
  .user__page-links {
    margin-left: 10%;
  }

  .user__page-child {
    width: 100%;
  }
}

@media only screen and (max-width: 754px) {
  .user__page-links, .user__page-child {
    width: 80%;
    margin: 0 auto;
  }

  .subscription-cancelled {
    width: unset;
  }

  .user__page {
    height: 980px;
  }
}

@media only screen and (max-width: 1020px) {
  .userinfoCircles {
    display: none;
  }
}

@media only screen and (max-width: 642px) {
  .user_info {
    align-items: center;
    flex-direction: column;
  }

  .personal__information-form input {
    width: 397px!important;
  }

  .personal__information-buttons {
    gap: 15px;
  }
}

@media only screen and (max-width: 518px) {
  .personal__information-form input {
    width: 317px!important;
    height: 30px!important;
  }

  .personal__information-buttons button{
    width: 140px;
    height: 42px;
  }
}