@import './Variables/Variables.scss';

.bgColor {
  background: $background-color;
}

.first-section__notFound {
  display: flex;
  position: relative;
  width: 100%;
  height: 1000px;
  overflow: hidden;
}

.first-section-text__notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 732px;
  height: 837px;
  margin-left: 10%;
  gap: 50px;
}

.first-section-text__notFound a {
  width: 216px;
}

.first-section-text__notFound h2{
  @include h-text;
}

.first-section-text__notFound p {
  @include p-text;
}

.first-section-text__notFound button {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: white;
  background-color: #0b0b0b;
  width: 216px;
  height: 64px;
  border-radius: 8px;
}

.gradient__notFound {
  position: absolute;
  background-image: url("../assets/icons/LP_gradient.svg");
  background-repeat: no-repeat;
  background-size:cover;
  height: 837px;
  width: 556px;
  right: 0;
  z-index: 2;
}

.monitor {
  min-width: 732px;
  height: 469px;
  position: absolute;
  left: -280px;
  bottom: 200px;
  z-index: 5;
  object-fit: contain;
}

.phone {
  width: 341px;
  height: 467px;
  position: absolute;
  bottom: 0;
  right: 60px;
  z-index: 10;
}


@media only screen and (min-width: 1930px) {

  .gradient__notFound {
    background-image: url("../assets/icons/LP_gradient_big.svg");
    height: 837px;
    width: 791px;
  }

  .phone {
    right: 250px;
  }

  .first-section-text__notFound {
    margin-left: 20%;
  }
}

@media only screen and (min-width: 2300px) {
  .gradient__notFound {
    width: 944px;
  }
}

@media only screen and (max-width: 1356px) {


  .gradient__notFound {
    right: -100px;
  }
}

@media only screen and (max-width: 1228px) {
  .gradient__notFound {
    width: 100%;
    left: 0;
    background-size: cover;
    height: 550px;
  }
  .first-section__notFound{
    flex-direction: column;

  }
  .first-section-text__notFound {
    z-index: 1000;
    position: absolute;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }

  .first-section-text__notFound button {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1019px) {
  .gradient__notFound {
    margin-top: 45px;
  }
  .first-section-text__notFound {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 948px) {
  .first-section-text__notFound {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .withoutTruhu {
    width: 500px!important;
  }

  .withTruhu {
    width: 500px!important;
    top: 230px;
  }

  .monitor {
    min-width: 294px;
    height: 245px;
    left: 25%;
  }

  .phone {
    width: 165px;
    height: 217px;
    right: 20px;
  }

  .gradient__notFound {
    height: 357px;
  }

  .first-section__notFound {
    height: 700px;
  }

  .first-section-text__notFound {
    width: 100%;
    align-items: center;
    margin-top: 90px;
    gap: 25px;
    height: 300px;
  }

  .first-section-text__notFound h2{
    font-size: 28px;
    line-height: 28.8px;
  }

  .first-section-text__notFound p{
    font-size: 18px;
    line-height: 21.6px;
  }

  .first-section-text__notFound button{
    width: 200px;
    height: 59px;
  }

  .second-section-text__notFound {
    margin-top: 500px;
    margin-bottom: 0;
  }

  .second-section__notFound {
    height: 1000px;
  }

  .second-section-text__notFound h2{
    font-size: 28px;
    line-height: 28.8px;
  }

  .second-section-text__notFound p{
    font-size: 18px;
    line-height: 21.6px;
  }

  .second-section-text__notFound button{
    width: 200px;
    height: 59px;
  }

  .third-section__notFound {
    width: 320px;
    height: 645px;
  }

  .testimonials {
    width: 600px;
    height: 570px;
    background-color: #247e69;
    color: white;
    margin: 0 auto;
  }

  .testimonialsContent {
    position: relative;
    width: 500px;
    height: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 35px;
  }

  .testimonialsContent img {
    width: 30.44px;
    height: 19.13px;
  }

  .quote-bottom {
    bottom: 150px;
  }

  .testimonials p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .carousel.carousel-slider {
    height: 446px;
  }
  .carousel .control-dots{
    bottom: -16px;
  }
}

@media only screen and (max-width: 588px) {
  .testimonials {
    width: 400px;
    height: 580px;
  }

  .testimonialsContent {
    position: relative;
    width: 400px;
    gap: 20px;
    height: 580px;
  }

  .second-section-text__notFound {
    margin-top: 542px;
  }

  .withoutTruhu, .withTruhu {
    width: 430px!important;
  }

  .monitor {
    position: static;
    margin: 50px auto;
  }
  .carousel.carousel-slider {
    height: 490px;
}
}

@media only screen and (max-width: 479px) {
  .withoutTruhu, .withTruhu {
    width: 357px!important;
  }

  .second-section-text__notFound {
    margin-top: 477px;
  }

  .second-section__notFound {
    height: 873px;
  }

  .quote-bottom, .quote-top {
    display: none;
  }

  .testimonialsContent {
    width: 329px;
    height: 630px;
  }

  .carousel.carousel-slider {
    height: 544px;
  }

  .testimonials {
    width: 300px;
    height: 620px;
  }
}

@media only screen and (max-width: 391px) {

  .first-section-text__notFound p {
    text-align: center;
  }

  .withoutTruhu, .withTruhu {
    width: 300px!important;
  }
}
