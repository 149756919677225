$primary-color: #247E69;
$secondary-color: #0F6758;
$background-color: rgba(234, 234, 234, 0.5);
$dark-color: #0B0B0B;

@mixin p-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

@mixin h-text {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

@mixin button-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}