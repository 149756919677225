.feedback__page {
  width: 100%;
  position: relative;
  background: rgba(234, 234, 234, 0.5);
}

.feedback__flex {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 100%;
}

.form__feedback {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form__feedback form {
  height: 1048px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.feedback__submitted {
  height: 949px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 50%;
  text-align: center;
}

.form__feedback h2 {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

.form__feedback p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.form__feedback button {
  width: 188.57px;
  height: 66.42px;
  background-color: #000;
  color: white;
  font-size: 20px;
  font-weight: 400px;
  line-height: 24px;
  border-radius: 8px;
}

.form__feedback input{
  width: 499px;
  height: 46px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  padding: 5px 15px;
}

.feedback__header {
  width: 499px;
}

.form__feedback textarea {
  width: 499px;
  height: 104px;
  border-radius: 6px;
  padding: 5px 15px;
}

.feedback__img {
  width: 50%;
  height: 1048px;
  background: linear-gradient(to bottom left, #A2D3B6, #6BB68F);
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.girlFeedback {
  width: 780px;
  transform: scaleX(1);
}

.feedback__header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 23px;
}

.feedback__input {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.textarea__input {
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: 18px;
  margin-bottom: 29px;
}

.check_mark {
  width: 70px;
}

.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  padding: 30px;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 36px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: lightgray;
  transition: all 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 1120px) {
  .feedback__img {
    display: none;
  }

  .feedback__flex {
    justify-content: center;
    align-items: center;
  }

  .form__feedback {
    width: unset;
  }

  .form__feedback form {
    justify-content: center;
  }
  
  .feedback__flex, .form__feedback form{
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 652px) {
  .form__feedback textarea, .form__feedback input, .feedback__header, .feedback__input, .textarea__input {
    width: 300px;
  }

  .feedback__header h2, .feedback__submitted h2 {
    font-size: 36px;
    line-height: 48px;
  }

  .feedback__submitted {
    width: 90%;
  }

  .check_mark {
    width: 44px;
  }
}