@import './Variables/Variables.scss';

.bgColor {
  background: $background-color;
}

.first-section__landing {
  display: flex;
  position: relative;
  width: 100%;
  height: 1000px;
  overflow: hidden;
}

.first-section-text__landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 732px;
  height: 837px;
  margin-left: 10%;
  gap: 50px;
  padding: 20px;
}

.first-section-text__landing a {
  width: 216px;
}

.first-section-text__landing h2 {
  @include h-text;
  font-size: 80px;
}

.first-section-text__landing p {
  @include p-text;
}

.first-section-text__landing h1 {
  color: grey;
  font-size: 30px;
  line-height: 37px;
  margin-top: 20px;
}

.first-section-text__landing button {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: white;
  background-color: #0b0b0b;
  width: 216px;
  height: 64px;
  border-radius: 8px;
}

.gradient__landing {
  position: absolute;
  background-image: url('../assets/icons/LP_gradient.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 837px;
  width: 556px;
  right: 0;
  z-index: 2;
}

.monitor {
  min-width: 732px;
  height: 469px;
  position: absolute;
  left: -280px;
  bottom: 200px;
  z-index: 5;
  object-fit: contain;
}

.phone {
  width: 341px;
  height: 467px;
  position: absolute;
  bottom: 0;
  right: 60px;
  z-index: 10;
}

.circles1 {
  width: 355px;
  height: 410px;
  position: absolute;
  right: -50px;
  top: -150px;
  z-index: 1;
}

.circles2 {
  width: 438px;
  height: 355px;
  position: absolute;
  left: 0;
  top: 750px;
  z-index: 3000;
}

/* SECOND SECTION */

.second-section__landing {
  position: relative;
  display: flex;
  height: 760px;
  width: 100%;
  overflow: hidden;
}

.second-section__landing img {
  width: 940px;
  height: 651px;
}

.withoutTruhu {
  position: absolute;
  left: -300px;
  background: url('../assets/webp_images/without_truhu.webp');
  width: 948px;
  height: 653px;
}

.withTruhu {
  position: absolute;
  right: -300px;
  background: url('../assets/webp_images/with_truhu.webp');
  width: 940px;
  height: 651px;
}

.second-section-text__landing {
  width:100%;
  height: 704px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: radial-gradient(53.51% 54.51% at 54% 50%, #ffffff 20.83%, rgba(255, 255, 255, 0) 110%);
  gap: 30px;
}

.second-section-text__landing h2 {
  font-weight: 700;
  font-size: 46px;
  line-height: 55.2px;
}

.second-section-text__landing p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24.8px;
  text-align: center;
}

.second-section-text__landing button {
  width: 216px;
  height: 64px;
  border-radius: 8px;
  background-color: #247e69;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: white;
}

/* THIRD SECTION */

.third-section__landing {
  width: 100%;
  height: 735px;
  display: flex;
  position: relative;
}

.testimonialsImg {
  width: 497px;
  height: 459px;
  position: absolute;
  right: 800px;
  top: 70px;
  z-index: 300;
}

.testimonials {
  width: 885px;
  height: 690px;
  background-color: #247e69;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
}

.testimonialsContent {
  position: relative;
  width: 478px;
  height: 611px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 15px;
}

.quote-black{
  width: 100px;
}
.black_quote_wrapper{
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}
.big-brother {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 15px 45px 15px;
 }
 
 .main-container {
   position: relative;
   overflow: hidden;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: 20px;
 }
 
 .card-container {
   background-color: #f6f6f6;
   border-radius: 20px;
   max-width: 100%;
   width: 580px;
   height: 445px;
 }
 
 .card-bg {
   display: flex;
   flex-direction: column;
   justify-content: center;
   position: absolute;
   color: #f6f6f6;
   text-align: center;
   width: 580px;
   height: 418px;
   border-radius: 20px;
   z-index: 10;
   top: 0;
   margin-top: 340px;
   background-color: #247e69;
  //  transition: all ease-in-out 800ms;
 }
 
 .img-container {
//  transition: ease-in-out 800ms;
 margin-top: -412px;
 }
 
 .img-container img {
   border-radius: 25%;
   height: 120px;
   width: 120px;
   background: white;
   text-align: center;
   margin-left: 30px;
   box-shadow: 0 10px 20px rgb(36 126 105), 0 6px 6px rgba(0, 0, 0, 0.23);
   padding: 10px;
  //  transition: ease-in-out 800ms;
 }
 
 .meta-container {
   color: rgb(0, 83, 156, 1);
  //  transition: ease-in-out 800ms;
 }
 
 
 .test-container {
   filter: blur(15px);
   color: #555;
   font-size: 21px;
   line-height: 30px;
   margin: 0;
   padding: 10px 30px;
   transition: ease-in-out 800ms;
 }
 @media only screen and (max-width:345px){
  .test-container {
    padding: 10px 20px;
  }
 }
 
 .card-container:hover {
   
   .card-bg {
  //  margin-top: 300px;
   background-color: #247e69;
   }
   
 }

 
 .card-container {
   
   .test-container {
     filter: none;
   }
   
 }
 
 .card-container {
   
   .meta-container {
   color: white;
 }
   
 }
.author {
  text-align: left;
}

.author a {
  text-decoration: underline;
}

.carousel.carousel-slider {
  height: 480px;
}

.testimonialsContent div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.control-arrow,
.carousel-status {
  display: none;
}

.testimonialsContent img {
  width: 40.44px;
  height: 29.13px;
}

.quote-bottom {
  position: absolute;
  right: 20px;
  bottom: 88px;
}

@media only screen and (max-width: 449px) {
  .black_quote_wrapper{
    display: none;
  }}
.testimonials p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.author-name {
  font-weight: 800 !important;
}

@media only screen and (min-width: 1930px) {
  .third-section__landing {
    justify-content: center;
    width: 80%;
    margin: 0 auto;
  }

  .testimonialsImg {
    width: 497px;
    height: 459px;
    position: relative;
    left: 80px;
  }

  .testimonials {
    width: 885px;
    height: 629px;
    background-color: #247e69;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
  }

  .withoutTruhu {
    left: -50px;
  }

  .withTruhu {
    right: -50px;
  }

  .gradient__landing {
    background-image: url('../assets/icons/LP_gradient_big.svg');
    height: 837px;
    width: 791px;
  }

  .phone {
    right: 250px;
  }

  .first-section-text__landing {
    margin-left: 20%;
  }
  .first-section__landing{
    height: 971px;
  }
}

@media only screen and (min-width: 2300px) {
  .gradient__landing {
    width: 944px;
  }
  .phone {
    // right: 456px;
  }

  .withTruhu {
    right: 5%;
  }

  .withoutTruhu {
    left: 5%;
  }
}

@media only screen and (max-width: 1356px) {
  .withoutTruhu {
    left: -450px;
  }

  .withTruhu {
    right: -450px;
  }

  .third-section__landing {
    justify-content: center;
    width: 80%;
    margin: 0 auto;
  }

  .testimonials {
    width: 885px;
    height: 629px;
    background-color: #247e69;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
  }

  .testimonialsImg {
    display: none;
  }

  .gradient__landing {
    right: -100px;
  }
}

@media only screen and (max-width: 1228px) {
  .big-brother{
    // margin-top: -30px;
  }
  .gradient__landing {
    width: 100%;
    left: 0;
    background-size: cover;
    height: 550px;
  }
  .monitor {
    left: 13%;
    top: 30px;
  }
  .circles1,
  .circles2 {
    display: none;
  }
  .first-section__landing {
    flex-direction: column;
  }
  .first-section-text__landing {
    z-index: 1000;
    position: absolute;
    top: 400px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }

  .first-section-text__landing button {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1064px) {
  .withoutTruhu {
    top: 80px;
    left: -20%;
    background-size: cover !important;
    background: url('../assets/webp_images/without-truhu-mobile.webp');
  }

  .withTruhu {
    top: 500px;
    right: -20%;
    background-size: cover !important;
    background: url('../assets/webp_images/with-truhu-mobile.webp');
  }

  .second-section__landing {
    flex-direction: column;
    height: 1636px;
  }

  .second-section-text__landing {
    margin-top: 1200px;
    z-index: 9000;
    text-align: center;
    width: 80%;
  }
}

@media only screen and (max-width: 1019px) {
  .gradient__landing {
    margin-top: 45px;
  }
  .first-section-text__landing {
    padding-top: 20px;
  }
  .monitor {
    top: 55px;
  }
}

@media only screen and (max-width: 948px) {
  .withoutTruhu {
    width: 700px !important;
    background-size: contain !important;
    background-repeat: no-repeat;
  }

  .withTruhu {
    width: 700px !important;
    top: 330px;
    background-size: contain !important;
    background-repeat: no-repeat;
  }

  .monitor {
    left: 4%;
  }

  .first-section-text__landing {
    margin-top: 15px;
  }

  .second-section-text__landing {
    margin-top: 800px;
  }

  .second-section__landing {
    flex-direction: column;
    height: 1414px;
  }
}

@media only screen and (max-width: 700px) {
  .big-brother{
    // margin-top: -34px !important;
  }
  .withoutTruhu {
    width: 500px !important;
  }

  .withTruhu {
    width: 500px !important;
    top: 230px;
  }

  .monitor {
    min-width: 294px;
    height: 245px;
    left: 25%;
  }

.phone {
    height: 217px !important;
    right: 20px !important;
    width: 165px !important;
}

  .gradient__landing {
    height: 357px;
  }

  .first-section__landing {
    height: 785px;
  }

  .first-section-text__landing {
    top: 350px;
    width: 100%;
    align-items: center;
    margin-top: 118px;
    gap: 25px;
    height: 300px;
  }

  .first-section-text__landing h2 {
    font-size: 28px;
    line-height: 28.8px;
  }

  .first-section-text__landing p {
    font-size: 18px;
    line-height: 21.6px;
  }

  .first-section-text__landing button {
    width: 200px;
    height: 59px;
  }

  .second-section-text__landing {
    margin-top: 580px;
    margin-bottom: 0;
  }

  .second-section__landing {
    height: 1000px;
  }

  .second-section-text__landing h2 {
    font-size: 28px;
    line-height: 28.8px;
  }

  .second-section-text__landing p {
    font-size: 18px;
    line-height: 21.6px;
  }

  .second-section-text__landing button {
    width: 200px;
    height: 59px;
  }

  .third-section__landing {
    width: 320px;
    height: 645px;
  }

  .testimonials {
    width: 600px;
    height: 483px;
    background-color: #247e69;
    color: white;
    margin: 0 auto;
  }

  .testimonialsContent {
    position: relative;
    width: 500px;
    height: 531px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 35px;
  }

  .testimonialsContent img {
    width: 30.44px;
    height: 19.13px;
  }

  .quote-bottom {
    bottom: 150px;
  }

  .testimonials p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .carousel.carousel-slider {
    height: 416px;
  }
  .carousel .control-dots{
    bottom: -15px;
  }
}

@media only screen and (max-width: 588px) {
  .testimonials {
    width: 400px;
    height: 570px;
  }

  .testimonialsContent {
    position: relative;
    width: 400px;
    gap: 20px;
    height: 522px;
  }

  .second-section-text__landing {
    margin-top: 542px;
  }

  .first-section-text__landing {
    top: 380px;
  }

  .withoutTruhu,
  .withTruhu {
    width: 430px !important;
  }

  .monitor {
    position: static;
    margin: 50px auto;
  }

  .carousel.carousel-slider {
    height: 380px;
    height: 472px;
  }
}

@media only screen and (max-width: 479px) {
  .withoutTruhu,
  .withTruhu {
    width: 357px !important;
  }

  .second-section-text__landing {
    margin-top: 477px;
  }

  .second-section__landing {
    height: 873px;
  }

  .first-section-text__landing {
    top: 362px;
  }

  .quote-bottom,
  .quote-top {
    display: none;
  }

  .testimonialsContent {
    width: 329px;
    height: 583px;
  }

  .carousel.carousel-slider {
    height: 535px;
  }

  .testimonials {
    width: 300px;
    height: 586px;
  }
}

@media only screen and (max-width: 391px) {
  .first-section-text__landing {
    top: 350px;
  }

  .first-section-text__landing p {
    text-align: center;
  }

  .withoutTruhu,
  .withTruhu {
    width: 300px !important;
  }
}
