.sign__page {
  width: 100%;
  position: relative;
  background: rgba(234, 234, 234, 0.5);
}

.sign__header {
  position: absolute;
  top: 30px;
  left: 80px;
}

.sign__flex {
  display: flex;
  flex-direction: row;
}

.form {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.form > form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.subscribe-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  text-align: center;
}

.forgot__form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.forgot__form h2 {
  width: 501px;
  font-size: 36px;
}

.forgot__form-signed {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.forgot__form-signed h2 {
  font-size: 36px !important;
}

.forgot__form-signed div {
  display: flex;
  gap: 30px;
}

.forgot__form-signed div button {
  width: 265px;
  height: 64px;
}

.forgot__form-signed div button:last-child {
  background-color: black;
}

.change-password_buttons {
  display: flex;
  align-items: center;
  width: 85%;
  justify-content: space-between;
}

.change-password_form input {
  width: 450px !important;
}

.change-password_buttons button {
  width: 265px !important;
}

.form h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
}

.form p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.form span {
  color: #59bdef;
  text-decoration: underline;
}

.form button {
  width: 501px;
  height: 66px;
  background-color: #247e69;
  color: white;
  font-size: 20px;
  font-weight: 400px;
  line-height: 24px;
  border-radius: 8px;
}

.form input {
  width: 501px;
  height: 46px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  padding: 5px 15px;
}

.error-login {
  border: 1px solid red !important;
}

.checkbox-register label {
  display: inline;
}

.checkbox-register {
  width: 501px;
}

.checkbox-register input {
  width: 17px;
  height: 17px;
  display: inline;
  margin-right: 10px;
}

.span__error-login {
  color: red !important;
  text-decoration: none !important;
  display: block;
  width: 501px;
}

.form label {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.sign__img {
  width: 50%;
  height: 100%;
  // height: 1149px;
  background: url('../assets/webp_images/sign_in_img.webp');
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.reset__confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.reset__confirmation p {
  font-size: 36px;
}

.sign__circles {
  position: absolute;
  top: 200px;
}

.text-green {
  color: #247e69 !important;
}

.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.text-bold {
  color: black !important;
  text-decoration: none !important;
  font-weight: 600;
}

.text-password-reset {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (min-width: 1748px) {
  .sign__img {
    background: url('../assets/webp_images/sign_in_img_big.webp');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width: 1435px) {
  .sign__circles {
    display: none;
  }
}

@media only screen and (max-width: 1140px) {
  .subscribe-page h2 {
    font-size: 26px;
  }

  .reset__confirmation p {
    text-align: center;
    line-height: 48px;
  }
}

@media only screen and (max-width: 1100px) {
  .sign__img {
    display: none;
  }

  .sign__flex {
    justify-content: center;
  }
}

@media only screen and (max-width: 628px) {
  .form button,
  .form input,
  .checkbox-register,
  .change-password_form > div input,
  .register p,
  .register label,
  .register div label,
  .span__error-login,
  .form form label,
  .form form div label,
  .form form h2 {
    width: 301px !important;
  }
.form{
  margin-top: 8%;
}
  .register .checkbox-register input {
    width: 17px !important;
  }

  .subscribe-page button {
    width: 200px;
  }

  .change-password_buttons button {
    width: 130px !important;
    height: 50px !important;
  }

  .forgot__form h2 {
    width: unset;
    // font-size: 32px;
    font-size: 20px;
    line-height: 25px;
    margin-top: 2rem;
  }

  .change-password_form h2 {
    font-size: 34px;
  }

  .change-password_form input,
  .change-password_form > div input {
    height: 36px;
  }
}

@media only screen and (max-width: 579px) {
  .register h2 {
    margin-top: 73px;
    font-size: 36px;
  }

  .login h2 {
    font-size: 36px;
    margin-top: 1rem;
  }

  .register input,
  .login input {
    height: 30px;
  }

  .reset__confirmation p {
    font-size: 32px;
  }
}
