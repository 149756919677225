a {
  cursor: pointer;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

.date {
  color: #959595;
}

.loader-wrap {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form_select {
  width: 501px;
    height: 46px;
    border: 1px solid #d0d0d0;
    border-radius: 6px;
    padding: 5px 11px;
}

@media only screen and (max-width: 628px) {
  .form_select {
    width: 301px;
  }
}