a {
  cursor: pointer;
}

.flex-sb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
  align-items: center;

  a.colored-link {
    color: #26a9e0 !important;
  }

  img {
    height: 80%
  }
}

@media only screen and (max-width: 730px) {
  .flex-sb {
    justify-content: unset;
    img {
      height: 50%;
    }
  }
}

.date {
  color: #959595;
}

.loader-wrap {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
