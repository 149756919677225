.footer {
  width: 100%;
  height: 340px;
  background-color: #247e69;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  color: white;
  font-size: 20px;
  line-height: 24px;
}

.bold {
  font-weight: 800 !important;
}

.logo__foter {
  width: 107.37px;
  height: 22px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 200px;
  margin-top: 70px;
}

.content p {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
}

.content > * {
  margin-bottom: 12px;
}

.social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.copyright {
  position: absolute;
  bottom: 0;
}

.copyright p {
  margin: 20px;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
}

@media only screen and (max-width: 948px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    height: 600px;
  }

  .content {
    flex-direction: column;
    text-align: center;
    gap: 10px;
    height: 100px;
    justify-content: flex-end;
  }

  .logo__foter {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 535px) {
  .footer,
  .copyright p,
  .content p {
    font-size: 14px;
  }

  .footer {
    height: 650px;
  }

  .content {
    flex-direction: column;
    text-align: center;
    gap: 10px;
    height: 100px;
    justify-content: flex-end;
  }

  .logo__foter {
    margin: 0 auto;
  }

  .social img {
    width: 23.26px;
    height: 17.14px;
  }
}
