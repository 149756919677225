.products__list {
    display: flex;
    gap: 15px;
  }


  .subscribe__form{
    margin-bottom: 10%;
  }
  
  .product {
    border: 1px solid black;
    display: flex;
    width: 250px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .product button {
    width: 200px;
  }


  
  .alt__payment__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    z-index: 100;
    // max-height: 637px;
    justify-content: space-between;
  }
  
  .alt__payment__list h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26.4px;
  }
  
  .form__card {
    margin-top: 30px;
  }
  
  .alt__payment__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 45px;
  }
  
  .alt__payment__button {
    width: 265px !important;
  }
  
  .alt__payment__button-cancel {
    cursor: pointer;
    font-weight: 700 !important;
  }
  
  .product__style {
    border: 1px solid lightgray;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 5px 15px;
    width: 502px;
    background-color: white;
  }
  
  .alt__payment__list form {
    width: 502px;
  }
  
  .product__input {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .product__style:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .product__style:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .product__style div {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .product__style input {
    width: 16px !important;
    display: flex;
  }
  
  .product__description {
    width: 410px !important;
    padding-bottom: 10px;
  
    &.offer {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      row-gap: 5px;
  
      p.introductory__offer {
        font-size: 25px;
        font-weight: 600;
        color: #222222;
      }
      
      .regular__offer {
        font-size: 18px;
        font-weight: 400;
        color: #656565;
      }
    }
  }
  
  //LAYOUT
  
  .alt__payment__page {
    width: 100%;
    position: relative;
    background: rgba(234, 234, 234, 0.5);
    overflow: hidden;
  }
  
  .alt__payment__header {
    display: block;
  }
  
  .subscription-title {
    margin: 15px 0 15px 30px;
  }
  
  .alt__payment__header a img {
    margin-left: 0px;
    display: inline;
  }
  
  .alt__payment__flex {
    display: flex;
    justify-content: center;
    // flex-direction: column;
  }
  .alt__payment__page__subtitle1 {
    // margin: 1% auto;
    border: 2px solid #cbcbcb;
    padding: 6px 0px;
    background-color: #EFEFEF;
    font-size: 34px;
    text-align: center;
    font-weight: 600;
  }
  .alt__payment__page__subtitle2{
    // margin: 1% auto;
    border: 2px solid #cbcbcb;
    padding: 6px 0px;
    background-color: #EFEFEF;
    font-size: 34px;
    text-align: center;
    font-weight: 600;
  }
  .head__image{
    position: absolute;  
    top:30px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .desc__div{
    position: relative;  
    left:23%;
    text-align: left;
  }
  .desc__div__p{
    text-align: left;
  }
  .head__image2{
    // position: absolute;
    // top:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 10px 0px;
  }
 
  .img1{
    width: 2.5%;
  }
  .img2{
    width: 2%;
  }
  .img3{
    width: 5%;
  }
  .img4{
    width: 2%;
  }
  .img5{
    width: 5%;
    border-radius: 15px;
  }
  .img1b{
    width: 2.5%;
  }
  .img2b{
    width: 2%;
  }
  .img3b{
    width: 5%;
  }
  .img4b{
    width: 2%;
  }
  .img5b{
    width: 5%;
    border-radius: 10px;
  }
  .two__boxes{
    display: flex;
    flex-wrap: nowrap;
  }

  .alt__payment__form {
    width: 100%;
    // height: 1055px;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    position: relative;
    padding-top: 40px;
    background-color: #ffff;
  }
  
  .card-error {
    margin-top: 15px;
  }
  
  .alt__payment__form > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
  }
  
  .span__error-stripe {
    color: red !important;
    text-decoration: none !important;
    display: block;
    width: 501px;
  }
  
  .alt__payment__form h2 {
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
  }
  
  .alt__payment__form p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  
  .alt__payment__form span {
    color: #59bdef;
    text-decoration: underline;
  }
  
  .alt__payment__form button {
    // width: 501px;
    // height: 66px;
    background-color: #247e69;
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 8px;
    max-width: 395px;
  }
  
  .alt__payment__form .subscribe__button {
    width: 260px;
  }
  
  .alt__payment__form input {
    width: 501px;
    height: 46px;
    border: 1px solid #d0d0d0;
    border-radius: 6px;
    padding: 5px 15px;
  }
  
  .alt__payment__form label {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  
  .alt__payment__img {
    width: 50%;
    height: 1149px !important;
    background: url('../assets/webp_images/sign_in_img.webp');
    position: absolute;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    right: 0;
  }
  
  .alt__payment__circles {
    position: absolute;
    top: 200px;
  }
  
  .text-green {
    color: #247e69 !important;
  }
  
  .text-underline {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .text-bold {
    color: black !important;
    text-decoration: none !important;
    font-weight: 600 !important;
  }
  
  @media only screen and (max-width: 1421px) {
    .alt__payment__circles {
      display: none;
    }
  }
  
  @media only screen and (min-width: 1133px) {

    .alt__payment__flex {
      width: 100%;
    }
    .mac__div{
      width: 50%;
    }
    .win__div{
      width: 50%;
    }
    .head__image2{
      display: none;
    }
    .desc__div{
      position: relative;  
      left:23%;
    }
  }
  @media only screen and (max-width: 1133px) {
    .desc__div__p{
      text-align: center;
    }
    .desc__div{
      left: 0;
    }
    .head__image{
      display: none;
    }
    .alt__payment__img {
      display: none;
    }
  
    .alt__payment__flex {
      justify-content: center;
      flex-wrap: wrap;
    }
   
  }
  
  @media only screen and (max-width: 730px) {
    
    .alt__payment__page__subtitle1{
      border: none;
    }

    .alt__payment__page__subtitle2{
      border: none;
    }

    .alt__payment__form > form {
      margin-left: 0 !important;
      align-items: center;
    }
  
    // .alt__payment__form {
    //   width: 550px;
    // }
  
    .alt__payment__form h2 {
      font-size: 26px;
    }
  }
  
  @media only screen and (max-width: 768px) {
   
    .head_image2{
      gap: 15px;
    }
    .img1b{
      width: 5%;
    }
    .img2b{
      width: 4%;
    }
    .img3b{
      width: 10%;
    }
    .img4b{
      width: 4%;
    }
    .img5b{
      width: 12%;
      border-radius: 15px;
    }
  }
  @media only screen and (min-width: 1665px) {
    .head__image{
      top:30px;
      padding: 0px 20px;
      right: 40%;
    }
    .head__image2{
      display: none;
    }
  }
  @media only screen and (max-width: 628px) {
    .subscribe__form{
      margin-bottom: 0%;
    }
    .alt__payment__form button,
    .alt__payment__form input,
    .alt__payment__list form {
      // width: 301px;
    }
  
    .alt__payment__form .subscribe__button {
      width: 260px;
    }
  
    .alt__payment__form {
      // height: 1150px;
    }
  
    .product__style,
    .card__input-stripe {
      width: 350px;
      margin: 0 auto;
    }
  
    .product__description {
      width: 250px !important;
    }
  
    .introductory__offer {
      font-size: 19px;
    }
  
    .regular__offer {
      font-size: 16px;
    }
  
    .span__error-stripe {
      display: inline;
    }
  
    .alt__payment__button {
      width: 186px !important;
    }
  }
  
  @media only screen and (max-width: 389px) {
    .product__style,
    .card__input-stripe {
      width: 286px;
      margin: 0 auto;
    }
  
    .subscribe__button {
      align-self: center;
    }
  }
  
  .alt__payment__image__container {
    display: block;
  }
  .alt__payment__image__container__horizontal {
    // margin: 25px 0px;
  }
  
  .alt__payment__list h2 {
    text-align: center;
  }
  
  .alt__payment__form__right {
    background-color: #DEEEEA;
  }
  
  .download__mac__button {
    padding: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF4D;
  }
  
  .alt__payment__image__container.mac__container {
    padding-top: 42px;
  }
  
  .alt__bg__gradient {
    position: absolute;
    display: none;
    mix-blend-mode: overlay;
    transition: display .3s ease;
    z-index: 2;
  }
  
  @media only screen and (max-width: 577px) {
    .two__boxes{
      flex-wrap: wrap;
    }
    .head_image2{
      gap: 10px;
    }
    .img1b{
      width: 6%;
    }
    .img2b{
      width: 5%;
    }
    .img3b{
      width: 13%;
    }
    .img4b{
      width: 5%;
    }
    .img5b{
      width: 15%;
      border-radius: 15px;
    }
  }
  @media only screen and (min-width: 577px) {
    .alt__payment__flex {
      // flex-direction: row;
    }
    
    .alt__payment__form {
      width: 50%;
      padding-bottom: 90px;
      padding-top: 90px;
    }
  
    .alt__payment__image__container.mac__container img {
      height: auto;
    }
  
    .alt__payment__image__container img {
      height: auto;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .alt__payment__form:hover .alt__bg__gradient {
      display: block;
    }
    
  }
  @media only screen and (max-width: 1024px) {
   
    .alt__payment__flex {
      flex-wrap: wrap;
    }
    .alt__payment__page__subtitle2{
      border: none;
      padding: 15px 0px;
    }
    .alt__payment__page__subtitle1{
      border: none;
    }
  }
  
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 16s linear infinite;
    -moz-animation: rotating 16s linear infinite;
    -ms-animation: rotating 16s linear infinite;
    -o-animation: rotating 16s linear infinite;
    animation: rotating 16s linear infinite;
  }
  
  .later__container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
  }
  .later__top__row{
    flex-wrap: wrap;
  }
  .later__container .later__top__row, .later__container .later__bottom__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .later__container .later__top__row {
    gap: 10px;
  }
  
  .later__container .later__top__row span {
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
  
  .later__container .later__bottom__row span {
    text-decoration: none;
    color: #000;
    opacity: 60%;
  }
  
  .later__container .later__bottom__row img {
    height: 0px;
    opacity: 0;
    position: relative;
    left: -10px;
    transition: all .2s ease;
  }
  
  .later__container .later__bottom__row span::after {
    content: "";
    border-bottom: 2px solid #0000009c;
    width: 100%;
    display: block;
    top: 6px;
    position: relative;
    transition: border-bottom .2s ease;
  }
  
  .later__container:hover .later__bottom__row span::after {
    border-bottom: 2px solid transparent;
  }
  
  .later__container:hover .later__bottom__row img {
    height: auto;
    opacity: 1;
    left: 6px;
  }
  
  .alt__payment__list__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
  }
  
  .alt__payment__form::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    background: url('../assets/images/truhu_green_bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
  }
  
  .alt__payment__form:hover::after {
    opacity: 1;
  }
  
  .alt__payment__form::after {
    transition: opacity .3s ease;
  }
  
  .empty__20 {
    height: 20px;
  }

  .alt__payment__top__description {
    margin: 2% 5%;
    text-align: center;
    padding: 20px 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border-width: 1px;
    border-color: #d4d4d4;
    background-color: #ffff;
  }

  .alt__payment__top__description p {
    font-size: 18px;
  }
  
  .alt__payment__top__title__container {
    margin-top: 3%;
  }
  
  @media only screen and (max-width: 1019px) {
    .alt__payment__top__title__container {
      margin-top: calc(3% + 60px);
    }
  }


  .alt__payment__page__title {
    font-size: 38px;
    font-weight: 600;
    text-align: center;
  }