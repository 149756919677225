.subscribed {
  &_title {
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
  }

  &_subtitle {
    font-weight: 400;
    font-size: 24px !important;
    line-height: 36px;
  }

  &_congratulation {
    width: 80%;
  }

  &_truhuSpan {
    font-weight: 700 !important;
    text-decoration: unset !important;
    color: unset !important;
  }

  &_text {
    padding: 0px 50px;

    .blogRedirect {
      color: #247e69;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 800px) {
  .subscribed {
    &_text {
      display: none;
    }

    &_subtitle {
      display: none;
    }
  }
}
