@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .form-spacing label {
        font-weight: 500;
        margin-top: 8px;
    }

    .form-spacing input {
        padding: 0 4px;
        border-radius: 6px;
    }
}

@font-face {
    font-family: 'Lato', sans-serif;
    src: url(assets/fonts/Lato-Regular.ttf) format('truetype');
}

* {
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}