@import './Variables/Variables.scss';

.bgColor {
  background: $background-color;
}

.carousel .control-dots {
  position: absolute;
  bottom: 0px;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 557px;
}

.actionLinkContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  align-items: center;

  .label {
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
  }
}

.first-section__product {
  display: flex;
  position: relative;
  width: 100%;
  height: 1000px;
}

.circles_product {
  position: absolute;
  left: 0;
  top: 650px;
}

.circles2__product {
  position: absolute;
  right: 0;
  top: -100px;
}

.line__product {
  position: absolute;
  right: 240px;
  top: 470px;
  z-index: 3000;
}

.video {
  display: flex;
  align-items: center;
  gap: 5px;
}

.first-section-text__product {
  display: flex;
  flex-direction: column;
  height: 850px;
  gap: 30px;
  justify-content: center;
  margin-left: 10%;
  padding: 0 10px;
}

.first-section-text__product h1 {
  color: grey;
  font-size: 35px;
}

.first-section-text__product h2 {
  @include h-text;
}

.first-section-text__product p {
  @include p-text;
  color: #0b0b0b;
}

.third-section__product {
  ol {
    margin-bottom: 40px;
  }
}

.gradient__product {
  position: absolute;
  background-image: url('../assets/icons/product_gradient.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 837px;
  width: 556px;
  right: 0;
  z-index: 2;
}

.product1_img {
  width: 120%;
}

// .product1 {
//   width: 445px;
//   height: 590px;
//   position: absolute;
//   right: 250px;
//   top: 150px;
// }
.product1 {
  width: 1003px;
  height: 860px;
  position: absolute;
  right: 120px;
  top: -100px;
}

.links__product {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links__product button {
  display: flex;
  @include button-text;
}

.links__payment {
  display: flex;
  justify-content: space-between;
}

.subscribe__button {

  width: 216px;
  height: 64px;
  border-radius: 8px;
  background-color: #247e69;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}


.link__try {
  z-index: 9999 !important;
}

.free-trial__button {
  width: 216px;
  height: 64px;
  border-radius: 8px;
  background-color: #000;
  color: white;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  z-index: 10000000 !important;
}

.second-section__product {
  display: flex;
  position: relative;
  width: 100%;
  height: 700px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 120px;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

ol li {
  counter-increment: my-awesome-counter;
  display: flex;
  flex-direction: row;
  gap: 14px;
}

ol li::before {
  content: '' counter(my-awesome-counter) '.';
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #2b9280;
}

.lastItem::before {
  content: url('../assets/icons/Vector.svg');
}

ol li h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

ol li p {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #0b0b0b;
}

.second-section-text__product {
  width: 486px;
  // gap: 32px;
  // height: 705px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 50px;

  h2 {
    @include h-text;
  }
}

.third-section__product {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  padding: 0 10px;
}

.third-section__product h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 57.6px;
  margin-bottom: 30px;
}

.testimonials-header {
  @include h-text;
  text-align: center;
  margin: 100px 0;
  margin-bottom: 50px;
}

.forth-section__product {
  width: 100%;
  height: 850px;
  display: flex;
  position: relative;
  justify-content: center;
}

.testimonialsImg {
  width: 497px;
  height: 459px;
  position: absolute;
  right: 800px;
  top: 70px;
  z-index: 300;
}


.quote-black {
  width: 100px;
}

.black_quote_wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

.big-brother {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 15px 45px 15px;
}

.main-container {
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 20px;
}

.monitor {
  min-width: 732px;
  height: 469px;
  position: absolute;
  left: -174px !important;
  bottom: 200px;
  z-index: 5;
  object-fit: contain;
}

.phone {
  width: 341px;
  height: 467px;
  position: absolute;
  bottom: 0;
  right: 60px;
  z-index: 10;
}

.card-container {
  background-color: #f6f6f6;
  border-radius: 20px;
  max-width: 100%;
  width: 580px;
  height: 445px;
}

.card-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  color: #f6f6f6;
  text-align: center;
  width: 580px;
  height: 418px;
  border-radius: 20px;
  z-index: 10;
  top: 0;
  margin-top: 340px;
  background-color: #247e69;
  //  transition: all ease-in-out 800ms;
}

.img-container {
  //  transition: ease-in-out 800ms;
  margin-top: -412px;
}

@media only screen and (min-width:345px) {
  .test-container {
    padding: 10px 20px !important;
  }
}

.img-container img {
  border-radius: 25%;
  height: 120px;
  width: 120px;
  background: white;
  text-align: center;
  margin-left: 30px;
  box-shadow: 0 10px 20px rgb(36 126 105), 0 6px 6px rgba(0, 0, 0, 0.23);
  padding: 10px;
  //  transition: ease-in-out 800ms;
}

.meta-container {
  color: rgb(0, 83, 156, 1);
  //  transition: ease-in-out 800ms;
}


.test-container {
  filter: blur(15px);
  color: #555;
  font-size: 21px;
  line-height: 30px;
  margin: 0;
  padding: 10px 30px;
  transition: ease-in-out 800ms;
}

.card-container:hover {

  .card-bg {
    //  margin-top: 300px;
    background-color: #247e69;
  }

}

.card-container {

  .test-container {
    filter: none;
  }

}

.card-container {

  .meta-container {
    color: white;
  }

}

.testimonials {
  width: 885px;
  height: 690px;
  background-color: #247e69;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
}

.testimonialsContent {
  position: relative;
  width: 478px;
  height: 611px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 15px;
}

.author {
  width: 55%;
}

.author a {
  text-decoration: underline;
}

.testimonialsContent div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.testimonialsContent img {
  width: 40.44px;
  height: 29.13px;
}

.quote-bottom {
  position: absolute;
  right: 20px;
  bottom: 88px;
}

.testimonials p {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.author-name {
  font-weight: 800 !important;
}

.technical-explainer {
  .technical-explainer-paragraph {
    @include p-text;
    padding: 40px 0;
  }

  h2 {
    @include h-text;
    text-align: center;
  }

  margin-bottom: 100px;
  max-width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (min-width: 1930px) {
  .phone {
    right: 250px;
  }

  .forth-section__landing {
    justify-content: center;
    width: 80%;
    margin: 0 auto;
  }

  .testimonialsImg {
    width: 497px;
    height: 459px;
    position: relative;
    left: 80px;
  }

  .testimonials {
    width: 885px;
    height: 629px;
    background-color: #247e69;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
  }

  .second-section-text__product h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 57.6px;
  }

  .calibratePhone {
    width: 816px;
    height: 845px;
  }
}

@media only screen and (min-width: 2400px) {
  .phone {
    right: 456px !important;
  }

  .gradient__product {
    background-image: url('../assets/icons/product_gradient_large.svg');
    height: 837px;
    width: 791px;
  }

  .first-section-text__product {
    margin-left: 20%;
  }

  .line__product {
    right: 500px;
    top: 550px;
  }

  .gradient__product {
    width: 1000px;
  }

  .line__product {
    right: 653px;
  }

  .product1 {
    right: 300px;
    width: 801px;
  }
}


@media only screen and (max-width: 1356px) {
  .gradient__product {
    width: 100%;
    left: 0;
    background-size: cover;
    height: 450px;
  }

  .monitor {
    left: 20% !important;
    top: 30px !important;
    height: 401px !important;
  }

  .first-section-text__product {
    margin-top: 315px;
    width: 100%;
    text-align: center;
    margin-left: 0;

    .first-section-second-text {
      width: 100%;
    }
  }

  .links__product {
    justify-content: space-evenly;
  }

  .circles_product,
  .circles2__product,
  .line__product {
    display: none;
  }

  .product1 {
    width: 401px !important;
    height: auto !important;
    position: static;
    margin: -50px auto;
  }

  .second-section__product {
    flex-direction: column;
    height: 1370px;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 70px;
  }

  .links__payment {
    justify-content: center;
    align-items: center;
    gap: 30px;
    z-index: 10;
    width: 100%;
    margin-bottom: 2rem;
  }

  .forth-section__product {
    justify-content: center;
    width: 80%;
    margin: 0 auto;
  }

  .testimonials {
    width: 885px;
    height: 629px;
    background-color: #247e69;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
  }

  .testimonialsImg {
    display: none;
  }

  .monitor {
    // top: 55px;
  }
}

@media only screen and (max-width: 1019px) {
  .gradient__product {
    margin-top: 55px;
  }

  iframe {
    width: 500px;
    height: 300px;
  }

  .first-section__product {
    h1 {
      margin-top: 50px;
    }
  }

  .technical-explainer {
    .details {
      grid-template-columns: 1fr;
    }
  }

  .phone {
    // width: 341px !important;
    // height: 467px !important;
  }

  .monitor {
    left: 8% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .monitor {
    // min-width: 341px !important;/
    // height: 467px !important;
    height: 400px !important;
    left: 2% !important;
  }
}

@media only screen and (max-width: 700px) {
  .monitor {
    min-width: 341px !important;
    height: 390px !important;
    left: 14% !important;
  }

  // .phone {
  //   width: 165px;
  //   height: 217px;
  //   right: 20px;
  // }
  .first-section__product {
    h1 {
      margin-top: 140px;
    }
  }

  iframe {
    width: 500px;
    height: 300px;
  }

  .forth-section__product {
    width: 320px;
    height: 645px;
  }

  .third-section__product {
    h2 {
      text-align: center;
    }

    margin-bottom: 20px;
  }

  .testimonials {
    width: 600px;
    height: 483px;
    background-color: #247e69;
    color: white;
    margin: 0 auto;
  }

  .testimonialsContent {
    position: relative;
    width: 500px;
    height: 531px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 35px;
  }

  .testimonialsContent img {
    width: 30.44px;
    height: 19.13px;
  }

  .quote-bottom {
    bottom: 150px;
  }

  .testimonials p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 535px) {
  .gradient__product {
    height: 350px;
  }

  iframe {
    width: 400px;
    height: 300px;
  }

  .product1 {
    width: 263px !important;
    margin: 10px auto;
  }

  .first-section__product {
    height: 700px;

    h2 {
      font-size: 40px;
    }
  }

  .calibratePhone {
    width: 350px;
    height: auto;
  }

  .links__payment {
    flex-direction: column;
    gap: 15px;
  }

  .first-section-text__product {
    margin-top: 210px;

    .first-section-second-text {
      line-height: 40px;
    }
  }

  .monitor {
    position: static;
    height: 306px !important;
    margin: 17px auto !important;
  }

  .second-section-text__product h2,
  .third-section__product h2,
  .technical-explainer h2,
  .testimonials-header {
    font-size: 28px;
    line-height: 35px;
    // margin-top: 50px;
  }

  .testimonials-header {
    margin-bottom: 50px;
  }

  .first-section-text__product p,
  .second-section-text__product p,
  .third-section__product p {
    font-size: 18px;
    line-height: 21.6px;
  }

  .second-section-text__product {
    width: 70%;
    margin-bottom: 0px;
  }

  .second-section__product {
    margin-top: 260px;
  }

  .appStore {
    height: 46px;
  }

  .testimonials {
    // width: 400px;
    height: 550px;
  }

  .testimonialsContent {
    // width: 400px;
    gap: 20px;
  }

  .carousel.carousel-slider {
    // height: 380px;
    height: 472px;
  }
}

@media only screen and (max-width: 476px) {
  .first-section-text__product h1{
      margin-top: 40px;
    }
}
@media only screen and (max-width: 449px) {
  .monitor {
    margin: 28px auto !important;
  }

  .black_quote_wrapper {
    display: none;
  }

  iframe {
    width: 300px;
    height: 300px;
  }

  .first-section-text__product {
    height: 990px;

    h1 {
      // margin-top: 10px;
      line-height: 40px;
    }

    .first-section-second-text {
      font-size: 30px;
    }
  }

  .second-section__product {
    // height: 1627px;
    margin-top: 28rem;
    margin-bottom: 10rem;
    // justify-content: space-evenly;
  }
  
  .second-section-text__product {
    // height: 890px;

    h2 {
      margin-top: 1rem;
    }
  }
  
  .third-section__product{
    margin-top: 8rem;
  }

  .testimonialsContent {
    width: 329px;
    height: 583px;
  }

  .carousel.carousel-slider {
    height: 535px;
  }

  .testimonials {
    width: 300px;
    height: 586px;
  }

  .carousel .control-dots {
    bottom: -10px;
  }
}

@media only screen and (max-width: 340px) {
  .monitor {
    left: 1% !important;
    height: 254px !important;
  }

  .first-section-text__product {
    height: 970px;
  }
 
  .third-section__product{
    margin-top: 15rem;
  }

}