.invites__page {
  height: 445px;

  .admin__questions {
    height: 150px;
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    align-items: center;
    justify-content: center;

    .form_select {
      width: 180px;
    }

    .upload_btn {
      width: 120px;
      height: 50px;
      border-radius: 8px;
      background-color: #247e69;
      color: white;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .admin__section {
    margin-bottom: unset;
    .success_msg {
      text-align: center;
      color: #247e69;
    }

    .error_msg {
      text-align: center;
      color: #D32F2F;
    }
  }
}