.header {
  width: 100%;
  height: 122px;
  display: flex;
  justify-content: space-between;
  background-color: #F4F4F4;
  border-radius: 0px 0px 8px 8px;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  box-shadow: -15px 15px 10px -15px 	rgb(32,32,32, 0.5), 15px 15px 10px -15px 	rgb(32,32,32, 0.5);
  z-index: 1000;
  position: relative;
  transition: position 0.3s ease;
}


.sticky {
  position: sticky !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000 !important;
}

.links__header {
  display: flex;
 justify-content: space-between;
  gap: 30px;
}

.links__header a {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0B0B0B;
}

.links__header a:hover {
  color: #247E69;
}

.sign {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  width: 550px;
  justify-content: space-evenly;
  align-items: center;
}

.logo__header {
  margin-left: 48px;
}

.sign-up-btn {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  width: 154px;
  height: 46px;
  border-radius: 8px;
  background: #247E69;
  color: white;
}

.colored-link {
  color: #247E69!important;
  text-decoration: underline;
}

nav { 
  position: absolute!important;
  width: 100%;
  z-index: 3000;
}

.logged_links {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.6px;
  color: #0B0B0B;
}

.logged_links:active {
  background-color: #fff;
}

.logged_name {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #0B0B0B;

}

.align-items-center {
  align-items: center;
}

.logo_header-link {
  height: 22px;
}

.logged_links img {
  display: inline;
}

.logged_links-sign-out {
  color: #ED1C24;
}

.logged_email {
  color: #959595;
}

.header-circle {
  background-color: #247E69 !important;
  border-radius: 50%;
  height: 46px;
  width: 46px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
}

.mobile-circle {
  background-color: #247E69 !important;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 21.6px;
}