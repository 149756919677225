.contact__page {
  width: 100%;
  position: relative;
  background: rgba(234, 234, 234, 0.5);
  display: flex;
}

.circles_contact {
  position: absolute;
  left: 0;
  top: 120px;
}

.contact__flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  padding: 20px 20px;
}

.contact__info p {
  margin-bottom: 10px;
}

.form__contact {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.elipse_contact {
  position: absolute;
  right: 200px;
  bottom: 100px;
}

.form__contact form {
  z-index: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.form__contact.feedback-report form {
  gap: 18px;
}

.contact__submitted {
  width: 720px;
  height: 949px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.form__contact h2 {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
}

.form__contact p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.form__contact p.pre-line {
  white-space: pre-line;
}

.form__contact button {
  width: 188.57px;
  height: 66.42px;
  background-color: #000;
  color: white;
  font-size: 20px;
  font-weight: 400px;
  line-height: 24px;
  border-radius: 8px;
}

.form__contact input {
  width: 499px;
  height: 46px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  padding: 5px 15px;
}

.form__contact textarea {
  width: 499px;
  height: 104px;
  border-radius: 6px;
  padding: 5px 15px;
}

.contact__img {
  width: 50%;
  height: 100%;
  background: linear-gradient(to bottom right, #a2d3b6, #6bb68f);
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.girlContact {
  width: 780px;
  transform: scaleX(-1);
}

.contact__header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 23px;
}

.feedback-report .contact__header {
  margin-bottom: 0;
}

.contact__input {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.textarea__input {
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: 58px;
  margin-bottom: 100px;
}

.check_mark {
  width: 70px;
}

.contact__submitted {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
}

@media only screen and (max-width: 1748px) {
  .elipse_contact {
    right: 100px;
  }
}

@media only screen and (max-width: 1382px) {
  .circles_contact,
  .elipse_contact {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .form__contact form input,
  .form__contact .textarea__input,
  .form__contact textarea {
    width: 350px;
  }

  .textarea__input {
    margin-top: 10px;
    margin-bottom: 21px;
  }
}

@media only screen and (max-width: 880px) {
  .contact__img {
    display: none;
  }

  .contact__flex {
    justify-content: center;
    align-items: center;
  }

  .form__contact {
    width: unset;
  }

  .form__contact form {
    justify-content: center;
  }

  .contact__flex,
  .form__contact form,
  .contact__submitted {
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 393px) {
  .form__contact textarea,
  .textarea__input,
  .form__contact form input {
    width: 300px !important;
  }
}
