body {
  background: rgba(234, 234, 234, 0.5);
}

.faq__page {
  position: relative;
  overflow: hidden;
}

.circles1_faq {
  position: absolute;
  left: 0;
}

.circles2_faq {
  position: absolute;
  right: 0;
  top: 1000px;
}

.faq__questions {
  width: 80%;
  margin: 0 auto;
}

.faq__section {
  margin-bottom: 50px;
}

.faq__section--last {
  margin-bottom: 110px;
}

.faq__link {
  font-weight: 700;
  text-decoration: underline;
}

.faq__section p{
  margin-left: 1%;
  margin-top: 20px;
}

.faq__page h2 {
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  width: 80%;
  margin: 90px auto;
}

h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

p {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}

ul {
  list-style-type: disc;
  margin-left: 2%;
}

@media only screen and (max-width: 1682px) {
  .circles1_faq, .circles2_faq {
    display: none;
  }
}

@media only screen and (max-width: 1019px) {
  .tc-title, .faq__page h2 {
    font-size: 35px!important;
    text-align: left!important;
    padding: 0!important;
    padding-top: 100px!important;
  }
}

@media only screen and (max-width: 650px) {
  .faq__section p{
    font-size: 20px;
  }
  
  .faq__page h2, .tc-title {
    font-size: 28px!important;
    line-height: 36px!important;
  }
}